import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { loadDomPurify } from '@hakimo-ui/hakimo/util';
import { useState } from 'react';

export interface Props {
  locationId: string;
}

export function SOP(props: Props) {
  const { locationId } = props;
  const [sanitizedHtml, setSanitizedHtml] = useState<string>('');

  const queryResult = useSOP(
    {
      locationId,
    },
    async ({ sop_text }) => {
      const domPurify = await loadDomPurify();
      setSanitizedHtml(domPurify(sop_text));
    }
  );

  return (
    <div>
      <h2 className="flex h-9 items-center">Standard Operating Procedure</h2>
      <QueryResult queryResult={queryResult} loadingText="">
        {() => (
          <ul className="dark:bg-ondark-bg-2 bg-onlight-bg-2 mt-1 space-y-4 p-3">
            <div
              className="whitespace-pre-wrap text-sm"
              dangerouslySetInnerHTML={{
                __html: sanitizedHtml,
              }}
            />
          </ul>
        )}
      </QueryResult>
    </div>
  );
}

export default SOP;
