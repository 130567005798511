import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useId } from 'react';
interface Props {
  error?: boolean;
  errorText?: string;
  label?: string;
  value: string;
  type:
    | 'email'
    | 'text'
    | 'password'
    | 'date'
    | 'datetime-local'
    | 'time'
    | 'search';
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: string;
  max?: string;
  autoFocus?: boolean;
}
export function InputField(props: Props) {
  const {
    error = false,
    errorText,
    label,
    placeholder,
    autoFocus = false,
  } = props;

  const id = useId();

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={id}
          className="dark:text-dark-secondary-text mb-1 block text-sm text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        <input
          name={id}
          id={id}
          className={clsx(
            'dark:bg-dark-bg dark:text-dark-text dark:border-dark-border-surface block w-full rounded-md border-gray-300 focus:outline-none sm:text-sm',
            error &&
              'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500 dark:border-red-700 dark:text-red-200 dark:placeholder-red-600 dark:focus:ring-red-600'
          )}
          placeholder={placeholder || ''}
          aria-invalid={error ? 'true' : 'false'}
          autoFocus={autoFocus}
          {...props}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p
          className="dark: mt-2 text-sm text-red-600 dark:text-red-400"
          id={`${id}-error`}
        >
          {errorText || ''}
        </p>
      )}
    </div>
  );
}

export default InputField;
