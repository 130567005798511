import ProfileSection from '../profile-section/ProfileSection';
import Application from './application/Application';
import Notifications from './notifications/Notifications';

export function Preferences() {
  return (
    <>
      <ProfileSection title="Application" content={<Application />} />
      <ProfileSection title="Notifications" content={<Notifications />} />
    </>
  );
}

export default Preferences;
