import { Link } from 'react-router-dom';
import HakimoLogo from './HakimoLogo';

interface Props {
  short?: boolean;
}

function HakimoNavLink(props: Props) {
  const { short } = props;
  return (
    <Link
      to="/"
      className="focus-visible:ring-primary-500 dark:focus:ring-offset-dark-surface block rounded focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
    >
      <HakimoLogo full={!short} />
    </Link>
  );
}

export default HakimoNavLink;
