import { Label } from '@hakimo-ui/shared/ui-base';
import { Combobox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { ReactElement, useEffect, useState } from 'react';
import Option from '../Option';

interface Props<T> {
  value: T[];
  label?: string;
  onChange: (value: T[]) => void;
  displayValue: (item: T) => string;
  getItems: (query: string) => T[];
  id?: (item: T) => string;
  /*
  Custom renderer here must support removal of selected values if needed
  - the default rendered labels contain that logic.
  */
  customSelectedRenderer?: (value: T[]) => ReactElement;
}

export function MultiSelect<T>(props: Props<T>) {
  const {
    label,
    value: selected,
    onChange,
    displayValue,
    getItems,
    id: getId,
    customSelectedRenderer,
  } = props;

  const [query, setQuery] = useState('');
  const [items, setItems] = useState<T[]>([]);

  useEffect(() => {
    setItems(getItems(query));
  }, [query, getItems]);

  const onRemoveSelected = (index: number) => {
    onChange([...selected.slice(0, index), ...selected.slice(index + 1)]);
  };

  return (
    <Combobox as="div" value={selected} onChange={onChange} multiple>
      {label && (
        <Combobox.Label className="dark:text-dark-text mb-1 block text-xs text-gray-700">
          {label}
        </Combobox.Label>
      )}
      {selected.length > 0 && (
        <div className="mb-2 flex flex-wrap gap-1">
          {customSelectedRenderer
            ? customSelectedRenderer(selected)
            : selected.map((item, idx) => (
                <span key={getId ? getId(item) : idx}>
                  <Label
                    text={displayValue(item)}
                    removable
                    onClickRemove={() => onRemoveSelected(idx)}
                  />
                </span>
              ))}
        </div>
      )}
      <div className="relative">
        <Combobox.Input
          className="focus:border-primary-500 focus:ring-primary-500 dark:text-dark-text dark:bg-dark-bg w-full rounded border-gray-400 text-sm dark:border-gray-600"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() => query}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none focus:ring-1">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {items.length > 0 && (
          <Combobox.Options className="dark:bg-dark-bg dark:text-dark-text absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-600 sm:text-sm">
            {items.map((item, idx) => (
              <Option
                key={getId ? getId(item) : idx}
                item={item}
                displayValue={displayValue}
              />
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default MultiSelect;
