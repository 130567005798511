import clsx from 'clsx';
import { useId } from 'react';

interface Props {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

export function Checkbox(props: Props) {
  const { label, checked, disabled = false, onChange } = props;
  const id = useId();

  return (
    <div className="flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={id}
          type="checkbox"
          className={clsx(
            'text-primary-600 focus:ring-primary-500 focus:ring-offset-dark-bg h-4 w-4 rounded border-gray-300 disabled:bg-gray-100 dark:border-gray-600 dark:disabled:bg-gray-600',
            !checked && 'dark:bg-dark-bg'
          )}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label
            htmlFor={id}
            className={
              disabled
                ? 'text-gray-400 dark:text-gray-500'
                : 'text-gray-700: dark:text-dark-text'
            }
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

export default Checkbox;
