import { FilterValues, TimePeriod } from '@hakimo-ui/hakimo/types';
import { Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { isNotDefaultFilter } from '../../util/common';
import TimePeriodSelector from '../time-period-selector/TimePeriodSelector';

interface Props {
  period: TimePeriod;
  filterValues: Partial<FilterValues>;
  onChangePeriod: (period: TimePeriod) => void;
  onClickOpenFilterPanel: () => void;
  isLoading?: boolean;
}

export function FiltersHeader(props: Props) {
  const {
    period,
    onChangePeriod,
    onClickOpenFilterPanel,
    filterValues,
    isLoading,
  } = props;

  return (
    <div className="flex h-12 flex-shrink-0 items-center justify-between gap-4 pl-1">
      <TimePeriodSelector period={period} onChange={onChangePeriod} />
      <div className="flex items-center justify-end gap-4 pl-1">
        {isLoading && <HakimoSpinner />}
        <span className="pr-4">
          <Button
            variant="icon"
            onClick={onClickOpenFilterPanel}
            badge={isNotDefaultFilter(filterValues)}
          >
            <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
          </Button>
        </span>
      </div>
    </div>
  );
}

export default FiltersHeader;
