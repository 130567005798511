export function CollapseIcon() {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 12.341 14.977 L 27.684 14.977 C 28.144 14.977 28.451 15.285 28.451 15.749 C 28.451 16.211 28.144 16.521 27.684 16.521 L 12.341 16.521 C 11.881 16.521 11.575 16.211 11.575 15.749 C 11.575 15.285 11.881 14.977 12.341 14.977 Z M 4.67 5.72 L 27.684 5.72 C 28.144 5.72 28.451 6.027 28.451 6.492 C 28.451 6.955 28.144 7.263 27.684 7.263 L 4.67 7.263 C 4.209 7.263 3.903 6.955 3.903 6.492 C 3.903 6.027 4.209 5.72 4.67 5.72 Z M 4.67 24.235 L 27.684 24.235 C 28.144 24.235 28.451 24.543 28.451 25.006 C 28.451 25.469 28.144 25.778 27.684 25.778 L 4.67 25.778 C 4.209 25.778 3.903 25.469 3.903 25.006 C 3.903 24.543 4.209 24.235 4.67 24.235 Z M 9.811 20.145 C 10.117 19.837 10.117 19.376 9.811 19.065 L 6.511 15.749 L 9.811 12.431 C 10.117 12.123 10.117 11.66 9.811 11.352 C 9.503 11.042 9.043 11.042 8.736 11.352 L 4.363 15.749 L 8.736 20.145 C 8.89 20.301 9.043 20.377 9.273 20.377 C 9.503 20.377 9.657 20.301 9.811 20.145 Z" />
    </svg>
  );
}
