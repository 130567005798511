import {
  AudioRecorder,
  LiveViewSwitch,
  ShareResource,
} from '@hakimo-ui/hakimo/feature-shared';
import { CameraDetail } from '@hakimo-ui/hakimo/types';
import {
  trackEvent,
  useCanUpdateArmedStatus,
  useCanUpdateCamera,
} from '@hakimo-ui/hakimo/util';
import { TabItem, Tabs } from '@hakimo-ui/shared/ui-base';
import { useMemo, useState } from 'react';
import { CameraDetailViews, CameraIntegrationType } from '../constants';
import CameraMenu from './camera-menu/CameraMenu';
import LabelCamera from './label-camera/LabelCamera';
import PlaybackVideo from './playback/PlaybackVideo';
import { CameraSettings } from './settings/CameraSettings';
import { viewItems } from './util';

interface Props {
  camId: string;
  camType?: string;
  camera: CameraDetail;
  onDeletedCamera: () => void;
}

export function CameraDetailsView(props: Props) {
  const { camId, camType, camera, onDeletedCamera } = props;
  const canUpdateArmedStatus = useCanUpdateArmedStatus();
  const canUpdateCamera = useCanUpdateCamera();

  const tabItems = useMemo(() => {
    const showSettings = canUpdateArmedStatus || canUpdateCamera;
    let opts: TabItem<CameraDetailViews>[] = viewItems;

    if (!showSettings) {
      opts = opts.filter((item) => item.id !== CameraDetailViews.SETTINGS);
    }
    if (camType !== CameraIntegrationType.RTSP) {
      opts = opts.filter(
        (item) =>
          item.id !== CameraDetailViews.LIVE &&
          item.id !== CameraDetailViews.PLAYBACK
      );
    }
    if (!camera.isPlaybackEnabled) {
      opts = opts.filter((item) => item.id !== CameraDetailViews.PLAYBACK);
    }
    return opts;
  }, [
    camType,
    camera.isPlaybackEnabled,
    canUpdateArmedStatus,
    canUpdateCamera,
  ]);

  const [activeViewId, setActiveViewId] = useState<CameraDetailViews>(
    tabItems[0].id
  );

  const [openShareLiveView, setOpenShareLiveView] = useState(false);
  const onChangeTab = (tabId: CameraDetailViews) => setActiveViewId(tabId);

  const onShare = () => {
    setOpenShareLiveView(true);
  };

  const onTalkdown = (cameraId: string) => {
    trackEvent('submit_camera_details_audio_talkdown', {
      camera_id: cameraId,
    });
  };

  return (
    <div className="px-4">
      <div className="dark:border-dark-border-bg mb-2 flex items-center justify-between border-b border-gray-300 py-2">
        <div className="flex flex-col justify-between gap-1">
          <h2 className="text-onlight-text-1 dark:text-ondark-text-1 pt-1 text-base">
            {camera.name}
          </h2>
          <div className="text-onlight-text-2 dark:text-ondark-text-2 inline-flex items-center text-xs">
            {camera.location && <span>{camera.location?.name}</span>}
          </div>
        </div>
        <CameraMenu onShare={onShare} />
        {openShareLiveView && (
          <ShareResource
            resourceId={camera.id}
            resourceType="live-view"
            onClose={() => setOpenShareLiveView(false)}
          />
        )}
      </div>
      <Tabs
        items={tabItems}
        activeTabId={activeViewId}
        onChange={onChangeTab}
      />
      <div className="py-6">
        {activeViewId === CameraDetailViews.SPECIAL_ZONES && (
          <LabelCamera cam={camera} />
        )}
        {activeViewId === CameraDetailViews.LIVE && (
          <div>
            {camera.audioDeviceMapped && (
              <AudioRecorder
                cameraId={camId}
                onTalkdown={onTalkdown}
                cameraName={camera.name}
              />
            )}
            <LiveViewSwitch camera={camera} />
          </div>
        )}
        {activeViewId === CameraDetailViews.PLAYBACK && (
          <PlaybackVideo
            cameraId={camId}
            cameraTimeZone={camera.location?.timezone || 'UTC'}
          />
        )}
        {activeViewId === CameraDetailViews.SETTINGS && (
          <CameraSettings camera={camera} onDeletedCamera={onDeletedCamera} />
        )}
      </div>
    </div>
  );
}
